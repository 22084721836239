import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NumberFinder from '../components/NumberFinder'
import useAuth from "../hooks/userAuth";
import { Button } from "@themesberg/react-bootstrap";
import Parse from '../vendor/Parse'

const IndexPage = () => {
  const { isLogin } = useAuth()
  return <Layout afterSearch={<></>}>
    <Button onClick={async () => {
      const res = await Parse.Cloud.run('getChecklistNumbers', {

      })
    }}>
      Fetch Latest Number
    </Button>
    <NumberFinder
      type="SGChecker"
      title="SG Number Finding"
      InformationComponent={<>
        <h3 className="text-center">whatapps us to know how to get the number</h3>
      </>}
    />
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
